require("@rails/ujs").start();
require("@rails/activestorage").start();
require('../vendor/responsive-paginate');

//import images
require.context('../images', true);

import 'bootstrap';
import 'ekko-lightbox';

import Plyr from 'plyr';
import gtag from 'ga-gtag';
import News from './news';
import AthleteRanking from './athlete_ranking';

$(window).on('load', function () {
  const players = Plyr.setup('.js-player');

  $('.pagination').rPage();

  $(document).on("click", '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });

  // Opens gi check tool video lighbox directly when navigated to the anchor link (from qrcode and IBJJF shop)
  if (window.location.href.endsWith('uniform#gi-checker-tool')) {
    const youtubeVideo = $('#gi-checker-tool').find('a');
    youtubeVideo.ekkoLightbox();
  }

  $(document).on("click", '[data-accordion]', function (event) {
    event.preventDefault();
    var element = $(this).find("i");

    if ($(this).attr('aria-expanded') === "false") {
      element.removeClass('fa-minus-square');
      element.addClass('fa-plus-square');
    } else {
      element.removeClass('fa-plus-square');
      element.addClass('fa-minus-square');
    }
  });

  $(".carousel").on("touchstart", function (event) {
    var xClick = event.originalEvent.touches[0].pageX;
    $(this).one("touchmove", function (event) {
      var xMove = event.originalEvent.touches[0].pageX;
      if (Math.floor(xClick - xMove) > 5) {
        $(this).carousel('next');
      } else if (Math.floor(xClick - xMove) < -5) {
        $(this).carousel('prev');
      }
    });
    $(".carousel").on("touchend", function () {
      $(this).off("touchmove");
    });
  });

  $('.navbar-toggler').on('click', function () {
    if ($(this).attr('aria-expanded') === "true") {
      $('body,html').css('overflow', 'auto')
    } else {
      $('body,html').css('overflow', 'hidden')
    }
  })

  $('.nav-main').on('click', function () {
    $('#navbar-user').collapse('hide')
  })

  $('.nav-user').on('click', function () {
    $('#navbar-header').collapse('hide')
  })

  $(document).ready(function () {
    AthleteRanking.initialize();
    News.initialize();

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var navbarElement = $('.navbar');

      // header transparent behaviour
      if (navbarElement.hasClass('home')) {
        if (scroll > 600) {
          navbarElement.removeClass('header-transparent-style');
          navbarElement.addClass('bg-ibjjf-color');
        } else {
          navbarElement.removeClass('bg-ibjjf-color');
          navbarElement.addClass('header-transparent-style');
        }
      }

      // scroll to top link behaviour
      var scrollLink = $('.scroll-to-top');

      if (scrollLink) {
        if (scroll > 1000) {
          scrollLink.addClass('show');
        } else {
          scrollLink.removeClass('show');
        }

        scrollLink.on('click', function (e) {
          e.preventDefault();
          window.scroll({ behavior: "smooth", top: 0 });
        });
      }
    });
  });

  $('.event-year-result').on('click', (e) => {
    if (App.RAILS_ENV === 'production') {
      const el = e.currentTarget
      const championshipData = `${$(el).data("n")} ${$(el).data('y')}`

      gtag('event', 'championship', {
        'event_category': 'Championship Year Result Click',
        'event_label': championshipData
      });
    }
  })
});
