import Rails from '@rails/ujs';

const News = {
  initialize() {
    this._onLoadMoreNewsButtonClick();
    this._onLoadMoreRelatedButtonClick();
    this._onNextPageRelatedNewsClick();
    this._onPrevPageRelatedNewsClick();
    this._onShareButtonClick();
    this._onLoadQuoteImageTemplate();
  },

  _onLoadQuoteImageTemplate() {
    $(".quote").each(function(_, el) {  
      const image = $(el).prev("p").find("img");
      const p = $(el).prev("p")

      // Verify if a img comes before a quote then apply the template, ps: all images come inside a p
      if (($(".quote").prev("p").find("img").length)) {
        $(el).wrap('<div class="row align-items-center"></div>');
        $(el).addClass("col-md-7");
        image.prependTo($(el).parent());
        image.addClass("col-md-5");
        p.remove()
      }
    });
  },   

  _onShareButtonClick() {
    $('#share-button').on("click", async (e) => {
      const el = $(e.currentTarget);
      const url = window.location.href;

      try {
        await navigator.share({ 
          title: el.data("news_title"),
          text: el.data("subtitle"),
          url: url
        });
      } catch (_) {
        await navigator.clipboard.writeText(url);    
        el.tooltip('show')
      }

      setTimeout(function() {
        el.tooltip('hide')
      }, 3000);
    });
  }, 

  _onLoadMoreNewsButtonClick() {
    $("#load-more-news").on("click", (e) => {
      const el = $(e.currentTarget);
      const parse = Range.prototype.createContextualFragment.bind(document.createRange())
      const page = el.data("page");
      const last_page = el.data("last-page");
  
      fetch(`news?page=${page}`, {
        method: "get",
        headers: {
          "Content-Type": "application/js",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      }).then((response) => {
        return response.text();
      }).then((body) => {
        const content = parse(body)

        $("#news").append(content);
        if(page >= last_page) {
          el.remove();
        } else {
          el.data("page", page + 1);
        }

        $('#load-more-news').blur();

      })
    });
  },

  _onLoadMoreRelatedButtonClick() {
    $("#load-more-related").on("click", (e) => {
      const el = $(e.currentTarget);
      const page = el.data("page");
      const newId = el.data("new-id");
      const last_page = el.data("last-page");

      if(page){
        this._fetchRelatedNews(newId, page).then((content) => {
          if(content){
            $("#related-news").append(content);

            if(page >= last_page || page > 3) {
              el.remove();
            } else {
              el.data("page", page + 1);
            }

            $('#load-more-related').blur();
          }
        })
      }
    });
  },

  _onNextPageRelatedNewsClick() {
    $("#next-related-news").on("click", (e) => {
      const el = $(e.currentTarget);
      const parse = Range.prototype.createContextualFragment.bind(document.createRange())

      const page = el.data("page");
      const newId = el.data("new-id");

      if(page){
        this._fetchRelatedNews(newId, page).then((content) => {
          if(content){
            $("#related-news").replaceWith(content);
            this._colorizeChevrons();
          }  
        })
      }
    });
  },

  _onPrevPageRelatedNewsClick() {
    $("#prev-related-news").on("click", (e) => {
      const el = $(e.currentTarget);
      const page = el.data("page");
      const newId = el.data("new-id")

      if(page){
        this._fetchRelatedNews(newId, page).then((content) => {
          if(content){
            $("#related-news").replaceWith(content);
            this._colorizeChevrons();
          }  
        })
      }
    });
  },

  _fetchRelatedNews(newId, page){
    return fetch(`${newId}/related_news?page=${page}`, {
      method: "get",
      headers: {
        "Content-Type": "application/js",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    }).then((response) => {
      return response.text();
    }).then((body) => {
      const parse = Range.prototype.createContextualFragment.bind(document.createRange())

      return parse(body);
    })
  },

  _colorizeChevrons(){
    const next_page = $("#related-news").data("next-page");
    const prev_page = $("#related-news").data("prev-page");
    if(prev_page){
      $("#prev-related-news").data("page", prev_page);
      $('#prev-related-news').removeClass('text-muted');
      $('#prev-related-news').addClass('text-primary');
    } else {
      $("#prev-related-news").data("page", null);
      $('#prev-related-news').addClass('text-muted');
      $('#prev-related-news').removeClass('text-primary');
    }
    if(next_page && next_page <= 4){
      $("#next-related-news").data("page", next_page);
      $('#next-related-news').removeClass('text-muted');
    } else {
      $("#next-related-news").data("page", null);
      $('#next-related-news').addClass('text-muted');
    }
  }
}

export default News;
